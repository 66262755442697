<script setup>
import { computed } from 'vue';
import { router, usePage } from '@inertiajs/vue3';
import {
    ArrowLeftEndOnRectangleIcon,
    ArrowRightEndOnRectangleIcon,
    ChevronRightIcon,
    UserCircleIcon,
} from '@heroicons/vue/24/outline/index.js';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import ResponsiveNavLinks from '@/Layouts/Partials/ResponsiveNavLinks.vue';
import JetResponsiveNavLink from '@/Components/ResponsiveNavLink.vue';
import { useNavigationStore } from '@/stores/navigation.js';

const store = useNavigationStore();

const page = usePage();
const mainItems = computed(() => page.props.navigations.main);
const topBarItems = computed(() => page.props.navigations.topbar);

const logout = () => router.post(route('logout'));
</script>

<template>
    <!-- eslint-disable vue/no-template-shadow -->
    <div class="border-b divide-y border-b-deep-teal-100 purple-theme:border-b-purple-100 divide-deep-teal-100 purple-theme:divide-purple-100">
        <div
            v-for="(item, index) in [...mainItems, ...topBarItems]"
            :key="index"
        >
            <Disclosure
                v-slot="{ open }"
                as="div"
                class="text-lg font-semibold text-deep-teal-700 purple-theme:text-purple-700"
                :default-open="item.is_parent"
            >
                <DisclosureButton
                    class="flex items-center justify-between w-full px-4 py-3 transition-colors focus:text-sunset-orange-500 focus:outline-none"
                    :class="{ 'text-sunset-orange-500': item.is_parent }"
                >
                    {{ item.title }}

                    <ChevronRightIcon
                        class="shrink-0 w-6 h-6 ml-2 transition-transform"
                        :class="[open && 'rotate-90']"
                        aria-hidden="true"
                    />
                </DisclosureButton>

                <DisclosurePanel
                    class="divide-y text-deep-teal-700 purple-theme:text-purple-700 bg-deep-teal-25 purple-theme:bg-purple-25 divide-deep-teal-100 purple-theme:divide-purple-100"
                >
                    <div
                        v-for="(children, cindex) in item.children"
                        :key="cindex"
                        :class="{ 'border-t border-t-deep-teal-100': cindex === 0 }"
                    >
                        <div v-if="children.children.length > 0">
                            <Disclosure
                                v-slot="{ open }"
                                as="div"
                                class="text-lg font-semibold text-deep-teal-700 purple-theme:text-purple-700"
                            >
                                <!-- TODO: add active state (text-sunset-orange-500) when route exists -->
                                <DisclosureButton
                                    class="flex items-center justify-between w-full py-3 pl-8 pr-4 text-base transition-colors focus:text-sunset-orange-500 focus:outline-none"
                                >
                                    {{ children.title }}

                                    <ChevronRightIcon
                                        class="shrink-0 w-6 h-6 ml-2 transition-transform"
                                        :class="[open && 'rotate-90']"
                                        aria-hidden="true"
                                    />
                                </DisclosureButton>

                                <DisclosurePanel
                                    class="py-2 border-t text-deep-teal-700 purple-theme:text-purple-700 bg-deep-teal-25  purple-theme:bg-purple-25 border-deep-teal-100 purple-theme:border-purple-100"
                                >
                                    <ResponsiveNavLinks
                                        :items="children.children"
                                        :level="2"
                                    />
                                </DisclosurePanel>
                            </Disclosure>
                        </div>

                        <div
                            v-else
                            class="divide-y divide-deep-teal-100 purple-theme:divide-purple-100"
                        >
                            <ResponsiveNavLinks :items="[children]" />
                        </div>
                    </div>
                </DisclosurePanel>
            </Disclosure>
        </div>

        <!-- Auth/Profile Dropdown -->
        <Disclosure
            v-slot="{ open }"
            as="div"
            class="text-lg font-semibold divide-y text-deep-teal-700 purple-theme:text-purple-700 divide-deep-teal-100 purple-theme:divide-purple-100"
        >
            <DisclosureButton
                class="flex items-center justify-between w-full px-4 py-3 transition-colors focus:text-sunset-orange-500 focus:outline-none"
                :class="[route().current('dashboard') || route().current('profile.show') || route().current('basic-data.show') ? 'text-sunset-orange-500' : null]"
            >
                <div class="flex items-center">
                    <UserCircleIcon
                        class="shrink-0 w-6 h-6 mr-2"
                        aria-hidden="true"
                    />

                    {{ $t('components.navigation.profile.main') }}
                </div>

                <ChevronRightIcon
                    class="shrink-0 w-6 h-6 ml-2 transition-transform"
                    :class="[open && 'rotate-90']"
                    aria-hidden="true"
                />
            </DisclosureButton>

            <DisclosurePanel
                class="divide-y text-deep-teal-700 purple-theme:text-purple-700 bg-deep-teal-25 purple-theme:bg-purple-25 divide-deep-teal-100 purple-theme:divide-purple-100"
            >
                <template v-if="$page.props.auth.user">
                    <JetResponsiveNavLink
                        font-size="text-base"
                        class="pl-4"
                        :href="route('dashboard')"
                        :active="route().current('dashboard')"
                        @click="store.setShowingNavigationDropdown(! store.showingNavigationDropdown)"
                    >
                        {{ $t('components.navigation.profile.dashboard') }}
                    </JetResponsiveNavLink>
                    <JetResponsiveNavLink
                        :href="route('event-orders.index')"
                        :active="route().current('event-orders.index')"
                        font-size="text-base"
                        class="pl-4"
                        @click="store.setShowingNavigationDropdown(! store.showingNavigationDropdown)"
                    >
                        {{ $t('components.navigation.profile.orders') }}
                    </JetResponsiveNavLink>
                    <JetResponsiveNavLink
                        :href="route('profile.show')"
                        :active="route().current('profile.show')"
                        font-size="text-base"
                        class="pl-4"
                    >
                        {{ $t('components.navigation.profile.account') }}
                    </JetResponsiveNavLink>
                    <JetResponsiveNavLink
                        :href="route('basic-data.show')"
                        :active="route().current('basic-data.show')"
                        font-size="text-base"
                        class="pl-4"
                        @click="store.setShowingNavigationDropdown(! store.showingNavigationDropdown)"
                    >
                        {{ $t('components.navigation.profile.basicData') }}
                    </JetResponsiveNavLink>

                    <!-- Authentication -->
                    <form @submit.prevent="logout">
                        <JetResponsiveNavLink
                            as="button"
                            :with-icon="true"
                            font-size="text-base"
                            class="pl-4"
                            @click="store.setShowingNavigationDropdown(! store.showingNavigationDropdown)"
                        >
                            <ArrowRightEndOnRectangleIcon
                                class="shrink-0 h-5 w-5 mr-1.5"
                                aria-hidden="true"
                            />

                            {{ $t('components.navigation.profile.logout') }}
                        </JetResponsiveNavLink>
                    </form>
                </template>
                <template v-else>
                    <JetResponsiveNavLink
                        :href="route('login')"
                        :with-icon="true"
                        font-size="text-base"
                        class="pl-4"
                        @click="store.setShowingNavigationDropdown(! store.showingNavigationDropdown)"
                    >
                        <ArrowLeftEndOnRectangleIcon
                            class="shrink-0 h-5 w-5 mr-1.5"
                            aria-hidden="true"
                        />

                        {{ $t('components.navigation.profile.login') }}
                    </JetResponsiveNavLink>
                </template>
            </DisclosurePanel>
        </Disclosure>
    </div>
    <!-- eslint-enable vue/no-template-shadow -->
</template>
