<script setup>
import { computed } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import {
    Popover,
    PopoverOverlay,
    PopoverButton,
    PopoverPanel,
    PopoverGroup,
} from '@headlessui/vue';
import { useWindowScroll } from '@vueuse/core';
import VerticalSubmenu from './VerticalSubmenu.vue';
import HorizontalSubmenu from './HorizontalSubmenu.vue';
import OpenSearch from './OpenSearch.vue';
import Icon from '@/Components/Icon.vue';

const page = usePage();
const { y } = useWindowScroll();
const overlayTop = computed(() => 114 - (y.value < 114 ? y.value - 114 : 114));
const mainItems = computed(() => page.props.navigations.main);
</script>

<template>
    <div class="hidden space-x-6 sm:flex sm:items-center">
        <OpenSearch />

        <PopoverGroup class="flex">
            <Popover
                v-for="(item, index) in mainItems"
                v-slot="{ open, close }"
                :key="index"
                class="z-10"
            >
                <PopoverOverlay
                    class="fixed backdrop-blur-sm inset-0 bg-black/60"
                    :style="`top: ${overlayTop}px;`"
                    @mouseover="close"
                />

                <PopoverButton
                    class="relative z-[1] font-semibold text-lg focus:text-sunset-orange-500 rounded-t-lg px-3 py-2.5 outline-none"
                    :class="{ 'bg-white': open, 'text-sunset-orange-500': open || item.is_parent }"
                    @mouseover.passive="!open && $event.target.click()"
                    @touchstart.prevent="$event.target.click()"
                >
                    {{ item.title }}
                </PopoverButton>

                <PopoverPanel
                    class="absolute inset-x-0 bg-white shadow-2xl pb-1"
                    :unmount="false"
                >
                    <header
                        v-if="item.children.some(c => c.primary)"
                        class="py-8"
                    >
                        <div class="flex flex-wrap items-center container px-4 mx-auto gap-8">
                            <Component
                                :is="children.is_external ? 'a' : Link"
                                v-for="(children, cindex) in item.children.filter(c => c.primary)"
                                :key="cindex"
                                class="inline-flex flex-none max-w-full items-center font-bold text-base rounded-3xl border-2 border-deep-teal-700 purple-theme:border-purple-700 hover:border-deep-teal-900 purple-theme:hover:border-purple-900 focus:border-deep-teal-900 purple-theme:focus:border-purple-900 focus:outline-none px-6 py-2.5 transition-colors"
                                :target="children.is_external ? '_blank' : null"
                                :rel="children.is_external ? 'noopener noreferrer' : null"
                                :class="[children.highlight ? 'text-white bg-deep-teal-700 purple-theme:bg-purple-700 hover:bg-deep-teal-900 purple-theme:hover:bg-purple-900 focus:bg-deep-teal-900 purple-theme:focus:bg-purple-900' : 'text-deep-teal-700 purple-theme:text-purple-700 bg-transparent hover:text-deep-teal-900 purple-theme:hover:text-purple-900 focus:text-deep-teal-900 purple-theme:focus:text-purple-900']"
                                :href="children.permalink"
                                @click="close"
                            >
                                <span class="truncate">{{ children.title }}</span>

                                <Icon
                                    v-if="children.icon"
                                    class="shrink-0 h-5 w-5 ml-2.5"
                                    aria-hidden="true"
                                    :icon="{ svg: children.icon }"
                                />
                            </Component>
                        </div>
                    </header>

                    <div
                        v-if="item.children.some(c => !c.primary)"
                        class="font-semibold bg-deep-teal-50 purple-theme:bg-purple-50 text-deep-teal-900 purple-theme:text-purple-900 py-8"
                    >
                        <div class="container px-4 mx-auto">
                            <Component
                                :is="item.type === 'horizontal' ? HorizontalSubmenu : VerticalSubmenu"
                                :items="item.children.filter(c => !c.primary)"
                                :close
                            />
                        </div>
                    </div>
                </PopoverPanel>
            </Popover>
        </PopoverGroup>
    </div>
</template>
