<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import LanguageSwitcher from './LanguageSwitcher.vue';
import JetDropdownLink from '@/Components/DropdownLink.vue';
import JetDropdown from '@/Components/Dropdown.vue';

const page = usePage();
const topBarItems = computed(() => page.props.navigations.topbar);
</script>

<template>
    <div
        v-for="(item, index) in topBarItems"
        :key="index"
        class="relative inline-flex ml-6"
    >
        <JetDropdown
            class="inline-flex"
            align="right"
            width="56"
        >
            <template #trigger>
                <span class="inline-flex">
                    <button
                        type="button"
                        class="inline-flex items-center font-semibold text-sm hover:text-sunset-orange-500 focus:text-sunset-orange-500 focus:outline-none transition-colors"
                    >
                        {{ item.title }}
                    </button>
                </span>
            </template>

            <template #content>
                <div class="block text-xs text-deep-teal-500 purple-theme:text-purple-500 px-3 pt-2 pb-1">
                    {{ item.title }}
                </div>

                <div class="font-normal">
                    <JetDropdownLink
                        v-for="(element, key) in item.children"
                        :key
                        :href="element.permalink"
                    >
                        {{ element.title }}
                    </JetDropdownLink>
                </div>
            </template>
        </JetDropdown>
    </div>

    <!-- Language Dropdown-->
    <LanguageSwitcher />
</template>
