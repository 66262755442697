<script setup>
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    active: Boolean,
    href: String,
    as: String,
    withIcon: Boolean,
    fontSize: {
        type: String,
        default: 'text-lg',
    },
});

const classes = [
    props.active
        ? 'text-sunset-orange-500'
        : 'text-deep-teal-700 purple-theme:text-purple-700',
    props.withIcon
        ? 'flex items-center'
        : 'block',
    'px-4 py-3 leading-tight hover:text-sunset-orange-500 focus:text-sunset-orange-400 focus:outline-none transition-colors',
];
</script>

<template>
    <div
        class="font-semibold"
        :class="fontSize"
    >
        <button
            v-if="as == 'button'"
            :class="classes"
            class="w-full text-left"
        >
            <slot />
        </button>

        <a
            v-else-if="as == 'a'"
            :href
            :class="classes"
            target="_blank"
        >
            <slot />
        </a>

        <Link
            v-else
            :href
            :class="classes"
        >
            <slot />
        </Link>
    </div>
</template>
