<script setup>
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import Header from './Partials/Header.vue';
import MobileMenu from './Partials/MobileMenu.vue';
import Footer from './Partials/Footer.vue';
import Search from './Partials/Search.vue';

const page = usePage();

const theme = computed(() => page.props?.theme || 'defaultTheme');
</script>

<template>
    <div :class="theme">
        <div class="flex flex-col min-h-screen">
            <!-- Header -->
            <nav class="bg-deep-teal-700 purple-theme:bg-purple-700 text-white">
                <Header />
                <MobileMenu />
            </nav>

            <!-- Page Content -->
            <main
                class="flex flex-col grow"
                role="main"
            >
                <slot />
            </main>

            <!-- Footer -->
            <Footer
                :extra-items="[{
                    class: 'ccm_overlay_trigger',
                    onclick: 'CCM?.openWidget(); return false;',
                    text: $t('components.footer.cookieConsent'),
                }]"
            />

            <!-- Environment Badge -->
            <div
                v-if="$page.props.environment !== 'production'"
                class="self-center lg:self-start fixed flex z-[1] select-none"
                aria-hidden="true"
            >
                <span
                    class="m-3 text-sm rounded-full bg-zinc-900 py-1 px-3 text-white opacity-40"
                >
                    {{ $page.props.environment }}
                </span>
            </div>

            <!-- Search -->
            <Search />
        </div>
    </div>
</template>
