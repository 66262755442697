<script setup>
import { Link } from '@inertiajs/vue3';
import Icon from '@/Components/Icon.vue';

defineProps({
    items: {
        type: Array,
        required: true,
    },
    close: {
        type: Function,
        required: true,
    },
});

const gridTemplateRowsMapping = {
    1: 'grid-rows-1',
    2: 'grid-rows-2',
    3: 'grid-rows-3',
    4: 'grid-rows-4',
    5: 'grid-rows-5',
    6: 'grid-rows-6',
};
</script>

<template>
    <div class="grid grid-cols-3 gap-12">
        <div
            v-for="(submenu, index) in items"
            :key="index"
            class="space-y-4"
        >
            <div
                v-if="submenu.title"
                class="text-xl"
            >
                {{ submenu.title }}
            </div>

            <ul
                v-if="submenu.children && submenu.children.length > 0"
                class="grid text-base border-l-2 border-l-deep-teal-100 purple-theme:border-l-purple-100 pl-4 grid-flow-col gap-x-4 gap-y-3"
                :class="[gridTemplateRowsMapping[submenu.itemsPerColumn || 6]]"
            >
                <li
                    v-for="(subitem, sindex) in submenu.children"
                    :key="sindex"
                    class="inline-flex"
                    @click="close"
                >
                    <Link
                        :href="subitem.permalink || ''"
                        class="inline-flex items-center hover:text-sunset-orange-500 focus:text-sunset-orange-500 outline-none"
                        :class="{ 'text-sunset-orange-500': subitem.is_current }"
                    >
                        <Icon
                            v-if="subitem.icon"
                            class="shrink-0 h-6 w-6 mr-4 opacity-75"
                            aria-hidden="true"
                            :icon="{ svg: subitem.icon }"
                        />

                        {{ subitem.title }}
                    </Link>
                </li>
            </ul>
        </div>
    </div>
</template>
